import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from "@angular/core";
import { EnvironmentConfig, ImageUtils, ModalService } from "../../../core/core";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";
import { tap, switchMap } from "rxjs/operators";
import { InventoryService } from "../../services/inventory.service";
import { DomSanitizer } from "@angular/platform-browser";
import Cropper from "cropperjs/dist/cropper.esm.js";

declare var environment: EnvironmentConfig;

@Component({
  selector: "common-inventory-images",
  templateUrl: "./inventory-images.component.html",
  styleUrls: ["./inventory-images.component.scss"],
})
export class InventoryImagesComponent implements OnInit {
  @Input() inventoryItem: any;
  @Input() showAdminActions: boolean = true;

  @Output() imageUploaded = new EventEmitter<number>();
  @Output() imageDeleted = new EventEmitter<any>();

  images = [
    { name: "Listing Photo #1" },
    { name: "Listing Photo #2" },
    { name: "Listing Photo #3" },
    { name: "Listing Photo #4" },
    { name: "Listing Photo #5" },
    { name: "Listing Photo #6" },
    { name: "Listing Photo #7" },
    { name: "Listing Photo #8" },
  ];

  imagePaths: string[] = [];
  imageFiles: any[] = [];
  indexForSingle: number;

  @ViewChild("modalContent") modalContent: HTMLElement;
  @ViewChild("croppr") croppr: HTMLElement;
  imgCropperSrc: string;
  cropInstance: any;

  constructor(
    private inventoryService: InventoryService,
    private toastrService: ToastrService,
    private domSanitizer: DomSanitizer,
    private modalService: ModalService,
  ) {}

  ngOnInit() {
    this.inventoryService.getImages(this.inventoryItem.id, this.inventoryItem.accountId).subscribe(result => {
      this.imagePaths = result.map(i => {
        return i?.storageModel?.storagePath ? `${environment.baseImagePath}/${i.storageModel.storagePath}` : "";
      });
    });
  }

  onMultipleImagesChange(event) {
    const images = event.target.files;
    if (images.length !== 8) {
      alert("Must upload 8 images");
      return;
    }

    var observables = [];
    for (let i = 0; i < images.length; i++) {
      var result = this.inventoryService.uploadImage(this.inventoryItem.id, this.inventoryItem.accountId, i, images[i]);
      result = result.pipe(
        tap(r => {
          this.imagePaths[i] = r.fullSize;
        }),
      );
      observables.push(result);
    }

    forkJoin(observables)
      .pipe(
        switchMap(resp => {
          return this.inventoryService.update(this.inventoryItem.id, this.inventoryItem);
        }),
      )
      .subscribe(resp => {
        this.toastrService.success("Images uploaded successfully.");
      });
  }

  setIndexForSingle(index) {
    this.indexForSingle = index;
  }

  onSingleImageChange(event) {
    const files = event.target.files;
    this.imageFiles[this.indexForSingle] = files[0];
    // even tho we are using cropper now we still want to resize image
    ImageUtils.resizeImage({
      file: files[0],
      maxSize: 1600,
    })
      .then((resizedImage: Blob) => {
        const objectUrl = window.URL.createObjectURL(resizedImage);
        this.imgCropperSrc = this.domSanitizer.bypassSecurityTrustUrl(objectUrl) as any;
        this.modalService.largeModal(this.modalContent, true);

        return;
      })
      .catch(function (err) {
        console.error(err);
      });
  }

  imageLoadedForCropper(event) {
    this.cropInstance = new Cropper(event.target, {
      viewMode: 1,
      dragMode: "move",
      aspectRatio: 16 / 9,
      restore: false,
      guides: true,
      center: false,
      highlight: true,
      background: false,
      cropBoxMovable: false,
      cropBoxResizable: false,
      toggleDragModeOnDblclick: false,
    });

    setTimeout(() => {
      const containerData = this.cropInstance.getContainerData();
      this.cropInstance.zoomTo(0, {
        x: containerData.width / 2,
        y: containerData.height / 2,
      });
    }, 800);
  }

  zoomOut() {
    this.cropInstance.zoom(-0.1);
  }

  zoomIn() {
    this.cropInstance.zoom(0.1);
  }

  saveCroppedPhoto(modal) {
    this.cropInstance.getCroppedCanvas().toBlob(blob => {
      const objectUrl = window.URL.createObjectURL(blob);
      this.imagePaths[this.indexForSingle] = this.domSanitizer.bypassSecurityTrustUrl(objectUrl) as any;
      var file = new File([blob], this.imageFiles[this.indexForSingle].name);
      this.imageFiles[this.indexForSingle] = file;
      modal.close();
    });
  }

  imgOnLoad(event) {
    if (this.indexForSingle == null) {
      return;
    }
    if (event.target.width < event.target.height) {
      this.imagePaths[this.indexForSingle] = null;
      this.imageFiles[this.indexForSingle] = null;
      alert("Landscape(horizontal) photo is required for listing photos. Please step back and rotate your phone while taking the photo.");
      return;
    }

    this.uploadImage(this.imageFiles[this.indexForSingle], this.indexForSingle);
  }

  uploadImage(image, index) {
    this.inventoryService.uploadImage(this.inventoryItem.id, this.inventoryItem.accountId, index, image).subscribe(result => {
      this.toastrService.success("Successfully uploaded photo");
      this.imageUploaded.emit(this.imageFiles.length);
    });
  }

  updateCoverImage(index) {
    this.inventoryService.updateCoverImage(this.inventoryItem.id, this.inventoryItem.accountId, index).subscribe(result => {
      this.inventoryItem.coverImagePosition = index;
      this.toastrService.success("Successfully set cover photo");
    });
  }

  deleteImage(index) {
    this.inventoryService.deleteImage(this.inventoryItem.id, this.inventoryItem.accountId, index).subscribe(result => {
      this.imagePaths[index] = "";
      this.toastrService.success("Successfully deleted photo");
      this.imageDeleted.emit();
    });
  }

  downloadAllPhotos() {
    this.inventoryService.downloadAllPhotos(this.inventoryItem.id).subscribe(() => {});
  }
}
